<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="pdvData.avatar"
              :text="avatarText(`${pdvData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(pdvData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ pdvData.nom }}
                </h4>
                <span class="card-text">
                  {{ pdvData.contact }}
                  <br>
                  {{ pdvData.type.nom }}
                </span>
              </div>
            </div>
          </div>

          <!-- User Stats -->
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="6"
          md="4"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageCommercial"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Commercial :</span>
              </th>
              <td class="pb-50">
                {{ pdvData.commercial.first_name }}
                {{ pdvData.commercial.last_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePeremption"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Produits proche de la péremption: </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ pdvData.nombre_pdvs }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageSeuilMini"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Produits en dessous du seuil minimal : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ pdvData.nombre_tournees }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageRupture"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1"> Produits en ruptures : </span>
              </th>
              <td class="pb-50">
                {{ pdvData.nombre_pdv }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <vue-apex-charts
            height="120"
            :options="earningsChart.chartOptions"
            :series="[53, 47]"
          />
          <h4 class="mb-0 text-center">
            Le stock par produit
          </h4>
        </b-col>
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Stock</span>
          </template>
          <ListeStockPDV :rows="[]" />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePeremption"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Visites</span>
          </template>
          <ListeVisitePDV :rows="[]" />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ListeVisitePDV from '@/views/afridia/visites/ListeVisitePDV.vue'
import ListeStockPDV from '@/views/afridia/stock_componants/ListeStockPDV.vue'
import { API_URL } from '@/helpers/global-scops'

const $earningsStrokeColor2 = 'rgb(1,132,38)'
const $earningsStrokeColor3 = 'rgba(40,199,111,0.24)'

export default {
  name: 'MainVue',
  components: {
    ListeStockPDV,
    ListeVisitePDV,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      listeStock: [],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Visites',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      pdvData: {
        id: 1,
        nom: 'pdv',
        telephone: '01 23 45 67',
        responsable: 'Directeur régional',
        canal: 'Canal moderne',
        nbr_pdvs: 10,
        nbr_tourners: 15,
        nbr_pdv: 50,
        status: 'Actif',
        action: '',
      },
      // eslint-disable-next-line global-require
      imagePeremption: require('@/assets/images/icons/passer.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/stock.png'),
      // eslint-disable-next-line global-require
      imageCommercial: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageSeuilMini: require('@/assets/images/icons/down-arrow.png'),
      // eslint-disable-next-line global-require
      imageRupture: require('@/assets/images/icons/rupture-de-stock.png'),
      userData: {},
      peremptionData: null,
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  created() {
    this.getPDV()
    this.getPeremptionData()
  },
  methods: {

    getPDV() {
      this.$http.get(`${API_URL}point_de_ventes/${this.$route.params.id}/`)
        .then(response => {
          this.pdvData = response.data
          console.log(this.pdvData)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPeremptionData() {
      this.$http.get(`${API_URL}lots-peremption/?id=${this.$route.params.id}`)
        .then(response => {
          this.peremptionData = response.data
          console.log(this.peremptionData)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
